import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { designSelector } from "../selectors/designSlice.selector";
import "../styles/app.scss";
import Sidebar from "./Sidebar";

const Layout = ({ children }) => {
  const { isSidebarClosed } = useSelector(designSelector);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    // Function to update windowWidth when the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add an event listener for the 'resize' event on the window
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isSmallScreen = windowWidth < 550;
  return (
    <div>
      <div
        className={
          isSmallScreen || isSidebarClosed ? "ya-main-after" : "ya-main"
        }
      >
        <Sidebar />
        <div className="ya-body">
          <main>{children}</main>
        </div>
      </div>
    </div>
  );
};

export default Layout;
