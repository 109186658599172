import PropTypes from "prop-types";

function ArrowChevronDownIcon({
  height = "20",
  width = "20",
  className,
  fill,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.71474 6.67859C4.23805 6.67859 3.8083 6.96574 3.62588 7.40614C3.44346 7.84654 3.54429 8.35347 3.88136 8.69054L10.1671 14.9763C10.6273 15.4365 11.3736 15.4365 11.8338 14.9763L18.1195 8.69054C18.4566 8.35347 18.5574 7.84654 18.375 7.40614C18.1926 6.96574 17.7629 6.67859 17.2862 6.67859H4.71474Z"
        fill={fill || "black"}
      />
    </svg>
  );
}

export { ArrowChevronDownIcon };

ArrowChevronDownIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  className: PropTypes.string,
};
