import httpClient from "./httpClient";

const ENDPOINT = "/admin/language";

export const getLanguageList = async () => {
  try {
    const response = await httpClient.get(ENDPOINT + "/list");
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const changeLanguage = async (value) => {
  try {
    const response = await httpClient.get(
      ENDPOINT + `/getLanguage?lang=${value}`,
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
