export function UserIcon({ height = "24", width = "24", className }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 23"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M11.5951 5.75157C11.2177 5.73909 10.8416 5.80105 10.4882 5.93392C10.1348 6.06679 9.81105 6.26797 9.5354 6.52596C9.25975 6.78396 9.03761 7.09372 8.88168 7.43756C8.72574 7.78141 8.63906 8.1526 8.62657 8.52994C8.61409 8.90728 8.67605 9.28339 8.80892 9.63679C8.94179 9.99019 9.14297 10.314 9.40096 10.5896C9.65896 10.8653 9.96872 11.0874 10.3126 11.2433C10.6564 11.3993 11.0276 11.4859 11.4049 11.4984C11.7823 11.5109 12.1584 11.4489 12.5118 11.3161C12.8652 11.1832 13.189 10.982 13.4646 10.724C13.7403 10.466 13.9624 10.1563 14.1183 9.81244C14.2743 9.46859 14.3609 9.0974 14.3734 8.72006C14.3859 8.34272 14.3239 7.96661 14.1911 7.61321C14.0582 7.25981 13.857 6.93605 13.599 6.6604C13.341 6.38475 13.0313 6.16261 12.6874 6.00668C12.3436 5.85074 11.9724 5.76406 11.5951 5.75157Z" />
      <path d="M11.5 12.9375C10.435 12.9375 9.39379 13.2526 8.50801 13.8431C7.62222 14.4336 6.93158 15.2731 6.52369 16.2557C6.43161 16.4775 6.45638 16.7306 6.58972 16.9303C6.72307 17.1301 6.94735 17.25 7.1875 17.25L15.8125 17.25C16.0527 17.25 16.2769 17.1301 16.4103 16.9303C16.5436 16.7306 16.5684 16.4775 16.4763 16.2557C16.0684 15.2731 15.3778 14.4336 14.492 13.8431C13.6062 13.2526 12.565 12.9375 11.5 12.9375Z" />
    </svg>
  );
}
