import React from "react";

function InstallationGuideIcon({ height = "24", width = "24", className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 30"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.375 5C7.8217 5 6.5625 6.2592 6.5625 7.8125V22.1875C6.5625 23.7408 7.8217 25 9.375 25H22.5C23.0178 25 23.4375 24.5803 23.4375 24.0625V7.8125C23.4375 6.2592 22.1783 5 20.625 5H9.375ZM8.4375 21.25C8.4375 20.2145 9.27697 19.375 10.3125 19.375H21.3322C21.4594 19.375 21.5625 19.4781 21.5625 19.6053V22.8947C21.5625 23.0219 21.4594 23.125 21.3322 23.125H10.3125C9.27697 23.125 8.4375 22.2855 8.4375 21.25ZM12.1875 8.75C11.6697 8.75 11.25 9.16973 11.25 9.6875C11.25 10.2053 11.6697 10.625 12.1875 10.625H17.8125C18.3303 10.625 18.75 10.2053 18.75 9.6875C18.75 9.16973 18.3303 8.75 17.8125 8.75H12.1875Z"
      />
    </svg>
  );
}

export { InstallationGuideIcon };
