import React from "react";

function ProductsIcon({ height = "24", width = "24", className }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="product-f-svgrepo-com 1">
        <g id="Group">
          <path
            id="Vector"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.0624 5.19077L22.4637 8.3092C23.74 9.04606 24.5262 10.4078 24.5262 11.8816V18.1184C24.5262 19.5921 23.74 20.9539 22.4637 21.6908L17.0624 24.8092C15.7861 25.5461 14.2137 25.5461 12.9374 24.8092L7.53613 21.6908C6.25985 20.9539 5.47363 19.5921 5.47363 18.1184V11.8816C5.47363 10.4078 6.25985 9.04606 7.53613 8.3092L12.9374 5.19077C14.2137 4.45391 15.7861 4.45391 17.0624 5.19077ZM19.8264 11.9457C19.6481 11.6104 19.2318 11.4832 18.8966 11.6614L14.992 13.7368L11.0875 11.6614C10.7522 11.4832 10.3359 11.6104 10.1577 11.9457C9.97941 12.2809 10.1067 12.6972 10.4419 12.8755L14.3045 14.9289L14.3045 19.5826C14.3045 19.9623 14.6123 20.2701 14.992 20.2701C15.3717 20.2701 15.6795 19.9623 15.6795 19.5826L15.6795 14.9289L19.5421 12.8755C19.8774 12.6972 20.0046 12.2809 19.8264 11.9457Z"
          />
        </g>
      </g>
    </svg>
  );
}

export { ProductsIcon };
