export function LeftChevronIcon({ height = "20", width = "20", className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 22 22"
      className={className}
    >
      <path d="M15.3213 4.71376C15.3213 4.23707 15.0341 3.80732 14.5937 3.6249C14.1533 3.44248 13.6464 3.54332 13.3093 3.88039L7.02363 10.1661C6.56337 10.6264 6.56337 11.3726 7.02363 11.8329L13.3093 18.1186C13.6464 18.4556 14.1533 18.5565 14.5937 18.374C15.0341 18.1916 15.3213 17.7619 15.3213 17.2852L15.3213 4.71376Z" />
    </svg>
  );
}
