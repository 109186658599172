import React from "react";

function HeartIcon({
  height = 19,
  width = 16,
  className,
  strokeColor = "black",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 19"
      xmlns="http://www.w3.org/2000/svg"
      fill="white"
      className={className}
    >
      <path
        d="M11.9153 3.61591L11.9151 3.61626L11.915 3.61653L11.915 3.61658L11.9148 3.61685L11.435 4.60616L11.0056 3.59419C11.0057 3.59437 11.0057 3.59435 11.0056 3.59413L11.0054 3.59377L11.0053 3.59344L11.0051 3.59304L11.004 3.59047L10.9945 3.56955C10.9853 3.54976 10.9705 3.5186 10.9498 3.4777C10.9083 3.39582 10.8434 3.27536 10.7533 3.12917C10.5726 2.83603 10.2933 2.44392 9.90178 2.05246C9.12319 1.27407 7.90523 0.500001 6.11004 0.500001C2.55119 0.500002 0.499998 3.44547 0.499999 6.64319C0.499999 7.87617 1.13933 9.23821 2.19013 10.6264C3.23296 12.0041 4.63446 13.3467 6.05303 14.5304C7.46898 15.7118 8.88726 16.7228 9.95285 17.439C10.4852 17.7968 10.9285 18.0804 11.238 18.2741C11.3244 18.3282 11.4004 18.3753 11.465 18.415C11.5304 18.3747 11.6075 18.3268 11.6953 18.2717C12.0068 18.0762 12.4529 17.7903 12.9885 17.4299C14.0608 16.7084 15.488 15.691 16.9129 14.5051C18.3404 13.3171 19.7508 11.9721 20.8002 10.597C21.8584 9.21047 22.5 7.85754 22.5 6.64318C22.5 5.08361 21.9994 3.54053 21.0455 2.39631C20.1005 1.26272 18.6955 0.5 16.8207 0.500001C15.134 0.500001 13.9135 1.26924 13.1009 2.0577C12.6941 2.45243 12.3943 2.84805 12.1968 3.14423C12.0983 3.29194 12.026 3.41383 11.9792 3.497C11.9558 3.53855 11.9388 3.57033 11.9282 3.59071L11.917 3.6125L11.9153 3.61591Z"
        stroke={strokeColor}
      />
    </svg>
  );
}

export { HeartIcon };
