import PropTypes from "prop-types";

function LogoutIcon({ height = "24", width = "24", className }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M27.5 8.27774V21.5563C27.5 22.9474 26.6071 24.3385 25.2041 24.8443L18.8265 27.2471C18.4439 27.3735 18.0613 27.5 17.551 27.5C16.9133 27.5 16.148 27.2471 15.5103 26.8678C15.2551 26.6148 14.8725 26.3619 14.7449 26.1089H9.89797C7.98471 26.1089 6.32654 24.5914 6.32654 22.568V21.3034C6.32654 20.7975 6.7092 20.2916 7.34695 20.2916C7.98471 20.2916 8.36736 20.671 8.36736 21.3034V22.568C8.36736 23.4533 9.13266 24.0855 9.89797 24.0855H14.1071V5.87497H9.89797C9.00511 5.87497 8.36736 6.50728 8.36736 7.39252V8.65713C8.36736 9.16298 7.98471 9.66883 7.34695 9.66883C6.7092 9.66883 6.32654 9.16298 6.32654 8.65713V7.39252C6.32654 5.49558 7.85716 3.85157 9.89797 3.85157H14.7449C15 3.59864 15.2551 3.34572 15.5103 3.09279C16.5306 2.46048 17.6786 2.33402 18.8265 2.71341L25.2041 5.11619C26.4796 5.49558 27.5 6.88667 27.5 8.27774Z" />
      <path d="M6.07142 18.5211C5.81632 18.5211 5.56122 18.3947 5.43367 18.2682L2.88265 15.739C2.7551 15.6125 2.7551 15.4861 2.62755 15.4861C2.62755 15.3596 2.5 15.2331 2.5 15.1067C2.5 14.9802 2.5 14.8537 2.62755 14.7273C2.62755 14.6008 2.7551 14.4743 2.88265 14.4743L5.43367 11.9451C5.81632 11.5657 6.45409 11.5657 6.83674 11.9451C7.21939 12.3245 7.21939 12.9568 6.83674 13.3362L5.94387 14.2215H11.1735C11.6837 14.2215 12.1939 14.6008 12.1939 15.2331C12.1939 15.8655 11.6837 15.992 11.1735 15.992H5.81632L6.70919 16.8771C7.09184 17.2565 7.09184 17.8888 6.70919 18.2682C6.58164 18.3947 6.32652 18.5211 6.07142 18.5211Z" />
    </svg>
  );
}

export { LogoutIcon };

LogoutIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  className: PropTypes.string,
};
