import { createSlice } from "@reduxjs/toolkit";

// Initial initialState
const initialState = {
  isSidebarClosed: false,
  isLoading: false,
};

// create a designSlice using createSlice function
const designSlice = createSlice({
  name: "design",
  initialState,
  reducers: {
    IS_SIDEBAR_CLOSED(state, action) {
      state.isSidebarClosed = action.payload;
    },
    IS_LOADING(state, action) {
      state.isLoading = action.payload;
    },
  },
});

export const { IS_SIDEBAR_CLOSED, IS_LOADING } = designSlice.actions;
export default designSlice.reducer;
