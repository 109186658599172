import React from "react";

function SettingIcon({ height = "24", width = "24", className }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9538 8.125C19.7257 8.125 18.5909 7.46981 17.9768 6.40625C16.6537 4.11458 13.346 4.11459 12.0229 6.40626C11.4088 7.46982 10.274 8.125 9.04595 8.125C6.39978 8.125 4.74592 10.9896 6.069 13.2812C6.68305 14.3448 6.68305 15.6551 6.069 16.7187C4.7459 19.0104 6.39978 21.875 9.04598 21.875C10.2741 21.875 11.4089 22.5302 12.0229 23.5937C13.346 25.8854 16.6538 25.8854 17.9769 23.5937C18.5909 22.5302 19.7257 21.875 20.9538 21.875C23.6 21.875 25.2539 19.0104 23.9308 16.7188C23.3167 15.6552 23.3167 14.3449 23.9308 13.2813C25.2539 10.9896 23.6 8.125 20.9538 8.125ZM12.6599 15.8798C12.5444 15.5725 12.4905 15.2455 12.5014 14.9173C12.5122 14.5892 12.5876 14.2664 12.7232 13.9674C12.8588 13.6685 13.052 13.3991 13.2916 13.1747C13.5313 12.9504 13.8129 12.7755 14.1202 12.6599C14.4275 12.5444 14.7545 12.4905 15.0827 12.5014C15.4108 12.5122 15.7336 12.5876 16.0326 12.7232C16.3315 12.8588 16.6009 13.052 16.8253 13.2916C17.0496 13.5313 17.2245 13.8129 17.3401 14.1202C17.4556 14.4275 17.5095 14.7545 17.4986 15.0827C17.4878 15.4108 17.4124 15.7336 17.2768 16.0326C17.1412 16.3315 16.948 16.6009 16.7084 16.8253C16.4687 17.0496 16.1871 17.2245 15.8798 17.3401C15.5725 17.4556 15.2455 17.5095 14.9173 17.4986C14.5892 17.4878 14.2664 17.4124 13.9674 17.2768C13.6685 17.1412 13.3991 16.948 13.1747 16.7084C12.9504 16.4687 12.7755 16.1871 12.6599 15.8798Z"
      />
    </svg>
  );
}

export { SettingIcon };
