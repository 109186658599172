import React from "react";

function UsersIcon({ height = "24", width = "24", className }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M15.124 7.50205C14.6318 7.48577 14.1412 7.56659 13.6803 7.7399C13.2193 7.91321 12.797 8.17561 12.4375 8.51212C12.0779 8.84864 11.7882 9.25268 11.5848 9.70117C11.3814 10.1497 11.2683 10.6338 11.2521 11.126C11.2358 11.6182 11.3166 12.1088 11.4899 12.5697C11.6632 13.0307 11.9256 13.453 12.2621 13.8125C12.5986 14.1721 13.0027 14.4618 13.4512 14.6652C13.8997 14.8686 14.3838 14.9817 14.876 14.9979C15.3682 15.0142 15.8588 14.9334 16.3197 14.7601C16.7807 14.5868 17.203 14.3244 17.5625 13.9879C17.9221 13.6514 18.2118 13.2473 18.4152 12.7988C18.6186 12.3503 18.7317 11.8662 18.7479 11.374C18.7642 10.8818 18.6834 10.3912 18.5101 9.93027C18.3368 9.46932 18.0744 9.04702 17.7379 8.68747C17.4014 8.32793 16.9973 8.03819 16.5488 7.8348C16.1003 7.6314 15.6162 7.51833 15.124 7.50205Z" />
      <path d="M15 16.875C13.6109 16.875 12.2528 17.286 11.0974 18.0562C9.94203 18.8265 9.04119 19.9215 8.50915 21.203C8.38905 21.4923 8.42137 21.8225 8.59529 22.083C8.76922 22.3436 9.06176 22.5 9.375 22.5L20.625 22.5C20.9382 22.5 21.2308 22.3436 21.4047 22.083C21.5786 21.8225 21.611 21.4923 21.4909 21.203C20.9588 19.9215 20.058 18.8265 18.9026 18.0562C17.7472 17.286 16.3891 16.875 15 16.875Z" />
    </svg>
  );
}

export { UsersIcon };
