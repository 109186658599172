/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link, NavLink } from "react-router-dom";
import { cn } from "../lib/utils";
import { designSelector } from "../selectors/designSlice.selector";
import { changeLanguage, getLanguageList } from "../services/language.service";
import "../styles/sidebar.scss";
import {
  DashboardIcon,
  InstallationGuideIcon,
  ProductsIcon,
  SettingIcon,
  UsersIcon,
} from "./icons";
import { LogoutIcon } from "./icons/LogoutIcon";
import { MainLogoIcon } from "./icons/MainLogoIcon";
import DropDown from "./ui/DropDown";

const Sidebar = () => {
  // Import necessary hooks,functions and initialize state variable
  const navigate = useNavigate();
  const { t } = useTranslation(["translations", "translationsSetting"]);
  const { isSidebarClosed } = useSelector(designSelector);
  // const location = useLocation();
  // const dispatch = useDispatch();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [optionsArray, setOptionsArray] = useState([]);
  const selectedLanguage = localStorage.getItem("lang") || "English";
  /*
   * Toggles the state of the sidebar between open and closed.
   * Dispatches an action to update the Redux store with the new sidebar state.
   */

  // const toggleSidebar = () => {
  // dispatch(IS_SIDEBAR_CLOSED(!isSidebarClosed))
  // }

  useEffect(() => {
    // Function to update windowWidth when the window is resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add an event listener for the 'resize' event on the window
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth]);

  // const isSmallScreen = windowWidth < 550;

  // Function to toggle the sidebar if it's a small screen.
  const closeSideBar = () => {
    // if(isSmallScreen) {
    // toggleSidebar();
    // }
  };
  const { control } = useForm();
  // Function to clear variables from localStorage
  const logOut = () => {
    localStorage.clear();
    // eslint-disable-next-line no-undef
    window.location.href = process.env.REACT_APP_SHOPIFY_REDIRECT_URL;
  };

  // array which is used in sidebar
  const pathArray = [
    {
      to: "/",
      Icon: DashboardIcon,
      name: "common.dashboard",
    },
    {
      to: "/settings",
      Icon: SettingIcon,
      name: "common.settings",
    },
    {
      to: "/installation-guide",
      Icon: InstallationGuideIcon,
      name: "common.installation-guide",
    },
    {
      to: "/wishlist-users",
      Icon: UsersIcon,
      name: "common.wishlist-users",
    },
    {
      to: "/wishlist-products",
      Icon: ProductsIcon,
      name: "common.wishlist-products",
    },
  ];

  const { data: languageList } = useQuery({
    queryKey: ["language-list"],
    queryFn: () => getLanguageList(),
  });

  useEffect(() => {
    if (languageList?.data) {
      const newOptionsArray = languageList.data.map((item) => ({
        label: item.language,
        value: item.language,
      }));
      setOptionsArray(newOptionsArray);
    }
  }, [languageList]);

  const { mutate: changeLanguageFromServer } = useMutation({
    mutationKey: ["change-language"],
    mutationFn: (payload) => changeLanguage(payload),
    onSuccess: (data) => {
      navigate(0);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  return (
    <div
      className={cn(
        "sidebar-main",
        "fixed  w-[300px] h-screen flex flex-col justify-between overflow-hidden top-0 left-0 bg-black",
        isSidebarClosed ? "collapsed" : "",
      )}
    >
      <div className="text-base leading-5 font-semibold text-white">
        <Link
          to="/"
          className="relative overflow-hidden "
          onClick={closeSideBar}
        >
          <MainLogoIcon />
        </Link>
        <div className="flex gap-2 items-center mt-1 mb-5 text-gray-500 font-medium">
          Language:
          {optionsArray.length > 0 && (
            <DropDown
              optionsArray={optionsArray}
              className={"pr-5 lang-dp"}
              control={control}
              value={
                selectedLanguage ? selectedLanguage : optionsArray[0].value
              }
              isBgBlack={true}
              onChange={(value) => {
                changeLanguageFromServer(value);
                localStorage.setItem("lang", value);
              }}
            />
          )}
        </div>
        <div className="relative flex flex-col gap-2 flex-grow">
          {pathArray.map(({ Icon, ...item }, index) => (
            <NavLink
              key={`__sidebar_${item.name}`}
              className={({ isActive }) =>
                cn(
                  "text-sm text-white font-semibold flex items-center gap-3 transition-all duration-300 ease-in-out [&>svg]:fill-white",
                  isActive
                    ? "bg-primary px-4 py-2.5 rounded-xl text-black [&>svg]:fill-black"
                    : "hover:text-primary py-2.5 rounded-xl [&>svg]:hover:fill-primary",
                )
              }
              onClick={closeSideBar}
              {...item}
            >
              <Icon className="h-7 w-7" />
              {t(item?.name)}
            </NavLink>
          ))}
        </div>
      </div>
      <Link
        className="gap-3 flex items-center text-md leading-5	font-medium	text-red-500 cursor-pointer"
        onClick={logOut}
      >
        <figure className="mb-0 overflow-hidden [&>svg]:fill-[#FF4848]">
          <LogoutIcon />
        </figure>
        {t("common.logout")}
      </Link>
    </div>
  );
};

export default Sidebar;
