// Importing the necessary modules from the i18next library
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const BASE_URL = process.env.REACT_APP_API_URL;

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: "English",
    lng: "English",
    backend: {
      loadPath: `${BASE_URL}/admin/language/getLanguage?lang=${
        localStorage.getItem("lang") || "English"
      }`,
    },
    ns: ["translations"],
    defaultNS: "translations",
  });

i18n.languages = ["English"];

export default i18n;
