export function RightChevronIcon({ height = "20", width = "20", className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={height}
      height={width}
      viewBox="0 0 22 22"
      className={className}
    >
      <path d="M6.67871 4.71376C6.67871 4.23707 6.96586 3.80732 7.40626 3.6249C7.84666 3.44248 8.35359 3.54332 8.69066 3.88039L14.9764 10.1661C15.4366 10.6264 15.4366 11.3726 14.9764 11.8329L8.69066 18.1186C8.35359 18.4556 7.84667 18.5565 7.40626 18.374C6.96586 18.1916 6.67871 17.7619 6.67871 17.2852L6.67871 4.71376Z" />
    </svg>
  );
}
