import React from "react";

function DashboardIcon({ height = "24", width = "24", className }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M14.0625 6.25C14.0625 5.90482 13.7827 5.625 13.4375 5.625H9.0625C7.16402 5.625 5.625 7.16402 5.625 9.0625V13.4375C5.625 13.7827 5.90482 14.0625 6.25 14.0625L13.4375 14.0625C13.7827 14.0625 14.0625 13.7827 14.0625 13.4375V6.25Z" />
      <path d="M6.25 15.9375C5.90482 15.9375 5.625 16.2173 5.625 16.5625V21.1C5.625 22.9087 7.09127 24.375 8.9 24.375H13.4375C13.7827 24.375 14.0625 24.0952 14.0625 23.75V16.5625C14.0625 16.2173 13.7827 15.9375 13.4375 15.9375H6.25Z" />
      <path d="M15.9375 23.75C15.9375 24.0952 16.2173 24.375 16.5625 24.375H21.1C22.9087 24.375 24.375 22.9087 24.375 21.1V16.5625C24.375 16.2173 24.0952 15.9375 23.75 15.9375H16.5625C16.2173 15.9375 15.9375 16.2173 15.9375 16.5625V23.75Z" />
      <path d="M23.75 14.0625C24.0952 14.0625 24.375 13.7827 24.375 13.4375V9.0625C24.375 7.16402 22.836 5.625 20.9375 5.625H16.5625C16.2173 5.625 15.9375 5.90482 15.9375 6.25V13.4375C15.9375 13.7827 16.2173 14.0625 16.5625 14.0625H23.75Z" />
    </svg>
  );
}

export { DashboardIcon };
