import React from "react";
import { cn } from "../../lib/utils";

function StarIcon({
  height = 19,
  width = 16,
  className,
  strokeColor = "black",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(`fill-transparent `, className)}
    >
      <g id="star">
        <path
          id="Union"
          d="M13.1599 3.87779C12.7987 2.70739 11.2013 2.70741 10.84 3.87779L9.28404 8.91846L4.22076 8.90689C3.01904 8.90415 2.56428 10.4824 3.50076 11.19L7.61161 14.296L6.03218 19.337C5.67743 20.4692 6.93057 21.4928 7.91213 20.7443L12 17.6271L16.0879 20.7443C17.0694 21.4928 18.3226 20.4692 17.9678 19.337L16.3884 14.296L20.4992 11.19C21.4357 10.4824 20.9809 8.90415 19.7792 8.90689L14.716 8.91846L13.1599 3.87779Z"
          fill="white"
          stroke={strokeColor}
        />
      </g>
    </svg>
  );
}

export { StarIcon };
