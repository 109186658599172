import { Select } from "antd";
import { Controller } from "react-hook-form";
import { cn } from "../../lib/utils";
import { ArrowChevronDownIcon } from "../icons";

export default function DropDown({
  optionsArray = [],
  className,
  name = "",
  control,
  isBgBlack = false,
  defaultValue,
  ...rest
}) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || optionsArray[0]?.value}
      render={({ field }) => (
        <Select
          className={cn("custom-dropdown", className)}
          popupClassName={`custom-dropdown`}
          suffixIcon={<ArrowChevronDownIcon fill={isBgBlack && "white"} />}
          options={optionsArray}
          {...field}
          {...rest}
        />
      )}
    />
  );
}
