import axios from "axios";

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://localhost:4578",
});

// Axios interceptor to add authorization header to requests
httpClient.interceptors.request.use(async function (config) {
  config.headers["lang"] = localStorage.getItem("lang");
  const token = localStorage.getItem("accessToken");
  if (token) {
    config.headers["authorization"] = token;
  }

  return config;
});

export default httpClient;
