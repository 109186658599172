import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import designSlice from "./redux/designSlice";

const store = configureStore({
  reducer: {
    designSlice: designSlice,
    middleware: [thunk],
    devTools: true,
  },
});

export default store;
