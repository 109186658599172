import React from "react";

function LeftArrowIcon({ height = "24", width = "24", className }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M9.52128 5.08775C9.52128 4.71522 9.288 4.37937 8.93023 4.23681C8.57246 4.09425 8.16064 4.17305 7.88681 4.43647L2.78284 9.34641C2.77499 9.35391 2.76727 9.36154 2.75969 9.3693C2.59865 9.5341 2.5 9.75596 2.5 10C2.5 10.1239 2.52543 10.2421 2.57154 10.35C2.61827 10.4596 2.6879 10.5623 2.78043 10.6513L7.88681 15.5635C8.16064 15.827 8.57246 15.9058 8.93023 15.7632C9.288 15.6206 9.52128 15.2848 9.52128 14.9123V10.921H16.5426C17.0713 10.921 17.5 10.5087 17.5 10C17.5 9.49132 17.0713 9.07895 16.5426 9.07895H9.52128V5.08775Z" />
    </svg>
  );
}

export { LeftArrowIcon };
