import { createSelector } from "@reduxjs/toolkit";

// create selectors
const sidebarClosed = (state) => state.designSlice.isSidebarClosed;
const loadingState = (state) => state.designSlice.isLoading;

export const designSelector = createSelector(
  [sidebarClosed, loadingState],
  (isSidebarClosed, isLoading) => {
    return { isSidebarClosed, isLoading };
  },
);
