import React from "react";
import { cn } from "../../lib/utils";

function BookMarkIcon({
  height = 19,
  width = 16,
  className,
  strokeColor = "black",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(`fill-transparent `, className)}
    >
      <g id="bookmark">
        <path
          id="Vector"
          d="M8.5 4C6.567 4 5 5.4958 5 7.34095V18.8697C5 20.7474 7.36363 21.7033 8.77672 20.397L11.7786 17.6219C11.902 17.5079 12.098 17.5079 12.2213 17.6219L15.2233 20.397C16.6364 21.7032 19 20.7474 19 18.8697V7.34095C19 5.4958 17.433 4 15.5 4H8.5Z"
          fill="white"
          stroke={strokeColor}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
}

export { BookMarkIcon };
